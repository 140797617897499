import { EmptyState } from "src/components/EmptyState"
import { ParadiseClientActions } from "src/components/Paradise/ParadiseClients/ParadiseClientDetails/ParadiseClientActions"
import { ParadiseClientOverview } from "src/components/Paradise/ParadiseClients/ParadiseClientDetails/ParadiseClientOverview/ParadiseClientOverview"
import { ParadiseClientSettings } from "src/components/Paradise/ParadiseClients/ParadiseClientDetails/ParadiseClientSettings/ParadiseClientSettings"
import { ParadiseDetailsHeading } from "src/components/Paradise/ParadiseDetailsHeading"
import { ParadiseDetailsLayout } from "src/components/Paradise/ParadiseDetatilsLayout"
import { LoadingWrapper } from "src/components/Paradise/sharedStyles"
import { useFetchApiClient } from "src/data/apiClients/apiClientQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import Svg404 from "src/ui/icons/404.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"

export function ParadiseClientDetails({ clientId }: { clientId: string }) {
  const { t } = useTranslate()

  const fetchClient = useFetchApiClient({
    clientId,
  })

  if (fetchClient.isError) {
    return (
      <EmptyState
        title={t(langKeys.failed_general_error_title)}
        body={t(langKeys.failed_general_error_body)}
        icon={<Svg404 />}
      />
    )
  }

  if (fetchClient.isLoading) {
    return (
      <LoadingWrapper>
        <MSkeleton height={40} width="30ch" />
        <MSkeleton height={20} width="40ch" />
      </LoadingWrapper>
    )
  }

  const client = fetchClient.data

  return (
    <ParadiseDetailsLayout
      meta={<ParadiseDetailsHeading>{client.name}</ParadiseDetailsHeading>}
      actions={<ParadiseClientActions client={client} />}
      left={<ParadiseClientOverview client={client} />}
      right={<ParadiseClientSettings client={client} />}
      banner={
        client.deleted && (
          <MBanner type="error" fullWidth>
            Client is deleted
          </MBanner>
        )
      }
      breadcrumbs={[
        {
          label: "Clients",
          to: Routes.ParadiseClients.location(),
        },
        {
          label: client.client_id,
          to: Routes.ParadiseClient.location(client.client_id),
        },
      ]}
      breadcrumbCopyValue={client.client_id}
    />
  )
}
