import { useState } from "react"

import { ParadiseConfirmationDialog } from "src/components/Paradise/ParadiseConfirmationDialog"
import { ActionsWrapper } from "src/components/Paradise/sharedStyles"
import { useDeleteApiClient } from "src/data/apiClients/apiClientQueries"
import { IApiClient } from "src/data/apiClients/apiClientTypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"

export function ParadiseClientActions({ client }: { client: IApiClient }) {
  const [showDeleteClientDialog, setShowDeleteClientDialog] = useState(false)

  const { navigate } = useRouter()
  const deleteApiClient = useDeleteApiClient()

  return (
    <div>
      <ActionsWrapper>
        <MButton
          variant="subtle"
          color="destructive"
          size="small"
          onClick={() => setShowDeleteClientDialog(true)}
        >
          Delete client
        </MButton>
      </ActionsWrapper>
      <ParadiseConfirmationDialog
        open={showDeleteClientDialog}
        title="Delete client?"
        content={
          <>
            <MText variant="body">
              Please insert the name ({client.name}) of the client to confirm.
            </MText>

            <MBanner type="error">
              This invalidates any tokens issued for this client immediately.
            </MBanner>
          </>
        }
        confirmLabel="Delete"
        confirmationText={client.name}
        onClose={() => setShowDeleteClientDialog(false)}
        loading={deleteApiClient.isLoading}
        error={deleteApiClient.isError && deleteApiClient.error.message}
        onConfirm={() => {
          deleteApiClient.mutate(
            {
              clientId: client.client_id,
            },
            {
              onSuccess: () => {
                navigate(Routes.ParadiseClients.location())
              },
            }
          )
        }}
      />
    </div>
  )
}
